<template>
  <div>
    <el-row>
      <el-col :span="18" :xs="24" :sm="24" :md="21" class="qr-code-generator-container">
        <div class="header-container">
          <div class="left-item">
            <img :src="require('@/assets/img/qrcode/FiberAPI-logo-grey.svg')" class="fiber-logo">
          </div>
          <div class="back-button" @click="$router.go(-1)">
            <i class="fas fa-chevron-left back-icon" />Back
          </div>
          <div class="center-item">
            <span class="title">
              <span class="brand-name">FiberAPI</span>
              FPS QR Code Generator
            </span>
          </div>
        </div>
        <div class="qr-code-generator-form">
          <el-row>
            <el-col :span="24" :xs="24" class="generator-col">
              <p class="description">*This is a free FPS QR code generator, the QR code generated is valid for 7 days (From the time it is generated).</p>
              <el-form ref="form" :model="form" :rules="rules" class="qr-code-form" :validate-on-rule-change="false">
                <el-row type="flex" align="middle" justify="space-between" class="qr-code-row">
                  <el-col class="hero-image-col">
                    <img :src="require('@/assets/img/qrcode/qrcode-generator.svg')" class="hero-image">
                  </el-col>
                  <el-col>
                    <el-form-item prop="proxy_type">
                      <span class="form-label">FPS ID / Phone Number / Email Address<span class="required">*</span></span>
                      <el-select v-model="form.proxy_type" clearable @change="clearProxyId">
                        <el-option
                          v-for="(item, index) in proxyTypeOptions"
                          :key="index"
                          :label="item.label"
                          :value="item.value"
                          class="qr-code-dropdown-item"
                        />
                      </el-select>
                    </el-form-item>
                    <el-form-item v-if="form.proxy_type === 'fps_id'" prop="fps_id" :error="formErrors.fps_id">
                      <span class="form-label">FPS ID<span class="required">*</span></span>
                      <el-input v-model="form.fps_id" />
                    </el-form-item>
                    <el-form-item v-if="form.proxy_type === 'phone_no'" prop="phone_no" :error="formErrors.phone_no">
                      <span class="form-label">Phone Number<span class="required">*</span></span>
                      <el-input v-model="form.phone_no" placeholder="e.g. +852-67891234" />
                    </el-form-item>
                    <el-form-item v-if="form.proxy_type === 'email'" prop="email" :error="formErrors.email">
                      <span class="form-label">Email Address<span class="required">*</span></span>
                      <el-input v-model="form.email" />
                    </el-form-item>
                    <el-form-item prop="currency">
                      <span class="form-label">Currency<span class="required">*</span></span>
                      <el-select v-model="form.currency" clearable>
                        <el-option
                          v-for="(item, index) in currencyOptions"
                          :key="index"
                          :label="item"
                          :value="item"
                          class="qr-code-dropdown-item"
                        />
                      </el-select>
                    </el-form-item>
                    <el-form-item prop="transaction_amount">
                      <span class="form-label">Transaction Amount</span>
                      <el-input v-model="form.transaction_amount" />
                    </el-form-item>
                    <!-- el-form-item prop="bill_no">
                      <span class="form-label">Bill Number</span>
                      <el-input v-model="form.bill_no" />
                    </el-form-item -->
                    <el-form-item prop="confirm" class="no-margin-bottom">
                      <el-checkbox v-model="form.confirm" class="confirm-checkbox">
                        I hereby confirm that I have read and agree the
                        <a href="https://fiberapi.com/zh/terms-of-use/" target="_blank" class="generator-link">Terms of Use</a> and
                        <a href="https://fiberapi.com/zh/privacy-policy/" target="_blank" class="generator-link">Privacy Policy</a>.
                      </el-checkbox>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
              <div class="button-container">
                <el-button type="primary" :disabled="!generation_allowed" @click="submit">Generate</el-button>
                <div v-if="!generation_allowed" class="el-form-item__error">*Please try again 1 minute later.</div>
              </div>
              <div class="remarks">
                <span class="title">Remarks:</span>
                <ol>
                  <li v-for="(item, index) in remarks" :key="index">
                    <template v-if="index !== 3">{{ item }}</template>
                    <template v-else>{{ item }} <a href="https://fiberapi.com/en/contact-2/" target="_blank" class="generator-link">Contact Us</a>.</template>
                  </li>
                </ol>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
const axios = require('axios')

export default {
  name: 'CreateQRCode',
  data() {
    return {
      form: {
        proxy_type: null,
        fps_id: '',
        phone_no: '',
        email: '',
        currency: null,
        transaction_amount: '',
        bill_no: '',
        confirm: false
      },
      formErrors: {
        fps_id: null,
        phone_no: null,
        email: null
      },
      landed_timer: 0,
      generation_allowed: true,
      currencyOptions: ['HKD'],
      proxyTypeOptions: [
        {
          label: 'FPS ID',
          value: 'fps_id'
        },
        {
          label: 'Phone Number',
          value: 'phone_no'
        },
        {
          label: 'Email Address',
          value: 'email'
        }
      ],
      remarks: [
        'To ensure the accuracy of the input information, please verify the details of payment request carefully before generating the QR code. FiberAPI shall not be responsible or liable in whatever manners.',
        'Embedded in the QR code generated by you is the information you inputted, such as FPS ID, currency and transaction amount (if applicable).',
        'FiberAPI will not store your inputted FPS ID. Please safeguard your generated QR code to prevent fraudsters from stealing your personal information for unlawful use.',
        'If you have any enquiries, please do not hesitate to '
      ]
    }
  },
  computed: {
    rules() {
      const number = new RegExp('^[0-9]*$')
      const contactNo = new RegExp('^[+][0-9]{1,}[-][0-9]{1,}$')
      const email = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]{3,}[@][a-zA-Z0-9-]{1,}[.](?:[a-zA-Z0-9-]+)(?:\.[a-zA-Z0-9-]+)*$")
      // const alphanumeric = new RegExp('^[a-zA-Z0-9]*$')
      const validateConfirm = (rule, value, callback) => {
        value !== true ? callback(new Error('You must agree to "Terms of Use" and "Privacy Policy".')) : callback()
      }
      var validateFPSID = (rule, value, callback) => {
        !number.test(value) ? callback(new Error('You must input FPS ID in number.')) : callback()
      }
      var validatePhoneNo = (rule, value, callback) => {
        !contactNo.test(value) ? callback(new Error('You must input Phone Number in +852-67891234 format.')) : callback()
      }
      var validateEmail = (rule, value, callback) => {
        !email.test(value) ? callback(new Error('You must input Email Address in email format.')) : callback()
      }
      var validateTransactionAmount = (rule, value, callback) => {
        const decimal = new RegExp('^[0-9]+\.?[0-9]{0,2}$')
        value.length > 0 && !decimal.test(value) ? callback(new Error('Transaction Amount must be non-negative number (2 decimal places)')) : callback()
      }
      /* var validateBillNumber = (rule, value, callback) => {
        value.length > 0 && !alphanumeric.test(value) ? callback(new Error('Bill Number must be letter or number.')) : callback()
      } */
      return {
        proxy_type: [
          {
            required: true,
            message: 'You must select FPS ID / Phone Number / Email Address.',
            trigger: 'change'
          }
        ],
        fps_id: [
          { required: this.form.proxy_type === 'fps_id', message: 'You must input FPS ID in number.', trigger: ['blur', 'focus'] },
          { validator: validateFPSID, trigger: ['blur', 'focus'] }
        ],
        phone_no: [
          { required: this.form.proxy_type === 'phone_no', message: 'Your must input Phone Number in +852-67891234 format.', trigger: ['blur', 'focus'] },
          { validator: validatePhoneNo, trigger: ['blur', 'focus'] }
        ],
        email: [
          { required: this.form.proxy_type === 'email', message: 'You must input Email Address in email format.', trigger: ['blur', 'focus'] },
          { validator: validateEmail, trigger: ['blur', 'focus'] }
        ],
        currency: [
          {
            required: true,
            message: 'You must select a currency.',
            trigger: 'change'
          }
        ],
        transaction_amount: [
          { validator: validateTransactionAmount, trigger: ['blur', 'focus'] }
        ],
        /* bill_no: [
          { validator: validateBillNumber, trigger: ['blur', 'focus'] }
        ], */
        confirm: [
          { validator: validateConfirm, trigger: 'change' }
        ]
      }
    }
  },
  watch: {
    landed_timer: {
      handler(v) {
        setTimeout(() => {
          this.generation_allowed = (Date.now() - this.$parent.last_generated_time) > 60000
          this.landed_timer++
        }, 1000)
      },
      immediate: true
    }
  },
  mounted() {
    this.generation_allowed = (Date.now() - this.$parent.last_generated_time) > 60000
  },
  methods: {
    submit() {
      this.$refs.form.validate(valid => {
        console.log('valid', valid)
        // only proceed with API call if it is valid.
        if (valid) {
          const header = {
            'x-openapihub-key': 'ed79cb8ae0ee444f8f03fe922197f614',
            'Content-Type': 'application/json'
          }
          var amount = {}
          this.$set(amount, 'currency', this.form.currency)
          if (this.form.transaction_amount !== '') {
            this.$set(amount, 'value', this.form.transaction_amount)
          }
          const request_body = {
            'user_reference_id': 'FPS_20210121_001',
            amount,
            'fps': {
              'fps_id': null,
              'phone_no': null,
              'email': null,
              'type': 'dynamic'
            }
          }
          request_body.fps[this.form.proxy_type] = this.form[this.form.proxy_type]
          const self = this
          axios.post('https://payment-request-api-qr-code-2m7n.gw.openapihub.com/v2/payment-requests', request_body, { headers: header })
            .then(function(response) {
              self.$gtag.event('/generate')
              self.generation_allowed = false
              self.$parent.countdown()
              self.switchPage(response.data.fps.fps_value)
            })
            .catch(function(error) {
              if (error.response.status === 422) {
                switch (self.form.proxy_type) {
                  case 'fps_id':
                    self.$set(self.formErrors, 'fps_id', 'You must input FPS ID in number.')
                    break
                  case 'phone_no':
                    self.$set(self.formErrors, 'phone_no', 'Your must input Phone Number in +852-67891234 format.')
                    break
                  case 'email':
                    self.$set(self.formErrors, 'email', 'You must input Email Address in email format.')
                    break
                }
              }
            })
        }
      })
    },
    switchPage(response) {
      this.$router.push({ name: 'fps-qrcode-generator-result', params: { qrCode: response, billNo: this.form.bill_no }})
    },
    clearProxyId() {
      this.$set(this.form, 'fps_id', '')
      this.$set(this.form, 'phone_no', '')
      this.$set(this.form, 'email', '')
      this.$refs.form.clearValidate('fps_id')
      this.$refs.form.clearValidate('phone_no')
      this.$refs.form.clearValidate('email')
    }
  },
  metaInfo() {
    return {
      title: 'OpenAPIHub',
      titleTemplate: 'Free FPS QR Code Generator | %s',
      link: [
        { rel: 'canonical', href: 'https://www.openapihub.com/fps-qrcode-generator/' }
      ],
      meta: [
        { name: 'description', content: 'FiberConnect Free FPS QR Code Generator enables you to create FPS payment QR Codes and accept payments instantly with ease.' },
        { property: 'og:site_name', content: 'OpenAPIHub' },
        { property: 'og:title', content: 'Free FPS QR Code Generator | OpenAPIHub' },
        { property: 'og:description', content: 'FiberConnect Free FPS QR Code Generator enables you to create FPS payment QR Codes and accept payments instantly with ease.' },
        { property: 'og:image', content: 'https://www.openapihub.com/assets/img/thumbnails/fps-qrcode-generator.jpg' },
        { property: 'og:url', content: 'https://www.openapihub.com/fps-qrcode-generator/' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.qr-code-generator-container {
  padding: 15px;
  padding-bottom: 0px;
  color: #404040;
  float: none;
  margin-left: auto;
  margin-right: auto;
}
.header-container {
  padding-right: 10px;
  padding-left: 10px;
  // height: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .left-item {
    margin-right: auto;
    font-weight: 700;
    font-size: 1.5rem;
    color: #1d4b54;
    .fiber-logo {
      width: 144px;
      height: auto;
    }
  }
  .back-button {
    margin-top: 52px;
    margin-bottom: 32px;
    margin-left: 5px;
    margin-right: auto;
    color: #29b8ac;
    cursor: pointer;
    .back-icon {
      margin-right: 8px;
    }
  }
  .center-item {
    text-align: center;
    font-size: 40px;
    line-height: 55px;
    font-weight: 700;
    color: #092825;
    .brand-name {
      color: #29b8ac;
    }
  }
}
.qr-code-generator-form {
  margin: auto;
  padding-top: 16px;
  padding-right: 10px;
  padding-left: 10px;
  max-width: 1200px;
  .generator-col {
    margin-left: auto;
    margin-right: auto;
    float: none;
  }
  .description {
    text-align: center;
    max-width: 540px;
    margin: auto;
  }
  .qr-code-form {
    .el-form-item {
      margin-bottom: 24px;
      .el-select {
        width: 100%;
      }
    }
    .el-form-item.no-margin-bottom {
      margin-bottom: 0px;
    }
  }
  .qr-code-row {
    flex-wrap: wrap;
    margin-top: 32px;
    .hero-image-col {
      text-align: center;
      margin-bottom: 32px;
      img {
        width: 100%;
        max-width: 540px;
        height: auto;
      }
    }
  }
  .form-label {
    margin: 8px 0px;
    display: block;
    font-size: 16px;
    font-weight: 700;
    color: #505050;
    line-height: 24px;
  }
  .required {
    color: #de4437;
    margin-left: 5px;
  }
  .confirm-checkbox {
    margin-top: 8px;
    margin-bottom: 0px;
    width: 100%;
    display: flex;
    align-items: flex-start;
  }
  .generator-link {
    color: #29b8ac;
    font-weight: 700;
  }
  .remarks {
    margin: 30px auto;
    color: #999999;
    line-height: 1;
    font-size: 12px;
    .title {
      font-weight: 700;
    }
    ol {
      padding-inline-start: 1em;
      margin-block-start: 8px;
      margin-left: 12px;
      list-style-type: decimal;
      li {
        line-height: 22.4px;
        margin-bottom: 4px;
        padding-left: 8px;
        text-align: justify;
      }
    }
  }
  .button-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 32px;
    > button {
      font-size: 16px;
      font-weight: 700;
      height: 40px;
      width: 120px;
    }
    button + button {
      margin-left: 24px;
    }
    > button.back-button {
      border-color: #7E7E7E;
      color: #7E7E7E;
    }
    > button:nth-last-child(1) {
      border-color: #29B8AC;
      background-color: #29B8AC;
      color: #FFFFFF;
    }

    .el-form-item__error {
      width: 100%;
      text-align: center;
    }
  }
}
.el-button--primary:disabled {
  border-color: #D0EDEA;
  background-color: #D0EDEA;
}
@media screen and (max-width:768px) {
  .header-container {
    padding: 0px;
    .back-button {
      margin-top: 39px;
    }
    .center-item {
      font-size: 28px;
      line-height: 38px;
    }
  }
  .description {
    text-align: left;
  }
  .qr-code-generator-form {
    padding-top: 8px;
    // .description {
    //   text-align: justify;
    // }
  }
}
</style>
